import { gql } from 'graphql-tag';
import {
  ORDER_DETAILS_FRAGMENT,
  SCHEDULE_DETAILS_FRAGMENT,
} from '@graphql/fragments/order';

// TODO: make use of fragments
export const GET_ORDER_BY_ID_QUERY = gql`
  query getOrderByBy($id: ID!) {
    node(id: $id, type: ORDER) {
      id
      ... on Order {
        ...OrderDetailsItem
      }
    }
  }
  ${ORDER_DETAILS_FRAGMENT}
`;

export const GET_ORDERS_BY_ORDER_ID = gql`
  query Node($nodeId: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $nodeId, type: $type) {
      id

      ... on Order {
        id
        subscription_id
        schedule_date
        total_items
        total_price
        trackingId
        order_products {
          product {
            id
            title
            taste
            product_badge
            image_url
            secondaryImages
            isActive
          }
          quantity
        }
        sideProducts {
          product {
            id
            title
            taste
            product_badge
            image_url
            secondaryImages
            isActive
          }
          quantity
        }
        pantryProducts {
          product {
            id
            title
            taste
            product_badge
            image_url
            secondaryImages
            isActive
          }
          quantity
        }
        subscription {
          id
          total_items
          plan_id
          plan {
            id
            count_of_dishes
          }
        }
      }
    }
  }
`;

export const GET_SCHEDULE_BY_ID = gql`
  query getScheduleById($id: ID!) {
    node(id: $id, type: SCHEDULE) {
      ... on Schedule {
        ...ScheduleDetailsItem
      }
    }
  }
  ${SCHEDULE_DETAILS_FRAGMENT}
`;
