import { GET_SUBSCRIPTION_DETAIL_FRAGMENT } from '@graphql/fragments/subscription';

export const updateSubscriptionDetailsCache = ({
  cache,
  data,
  id,
  isMealProduct,
  isPantryProduct,
}: any) => {
  // read old subscription fragment
  const oldSubscriptionDetail = cache.readFragment({
    id: `SubscriptionResponse:${id}`,
    fragment: GET_SUBSCRIPTION_DETAIL_FRAGMENT,
    fragmentName: 'GetSubscriptionDetail',
  }) as any;

  const { products } = data?.addProductFeedback || {};
  const feedbackProduct = products.length ? products[0] : {};

  let obj = { ...oldSubscriptionDetail };

  if (feedbackProduct?.rating || feedbackProduct?.rating === 0) {
    if (isMealProduct) {
      const subscriptionProducts =
        oldSubscriptionDetail?.subscription_products?.map(
          ({ product, quantity }: any) => {
            if (product?.id === feedbackProduct?.id) {
              return {
                product: { ...product, rating: feedbackProduct?.rating },
                quantity,
                __typename: 'OrderProduct',
              };
            } else {
              return {
                product,
                quantity,
                __typename: 'OrderProduct',
              };
            }
          }
        );

      obj = {
        ...obj,
        subscription_products: subscriptionProducts,
      };
    }

    if (isPantryProduct) {
      const pantryProducts = oldSubscriptionDetail?.pantryProducts?.map(
        ({ product, quantity }: any) => {
          if (product?.id === feedbackProduct?.id) {
            return {
              product: { ...product, rating: feedbackProduct?.rating },
              quantity,
              __typename: 'OrderProduct',
            };
          } else {
            return { product, quantity, __typename: 'OrderProduct' };
          }
        }
      );

      obj = {
        ...obj,
        pantryProducts: pantryProducts,
      };
    }

    // write subscription fragment
    cache.writeFragment({
      id: `SubscriptionResponse:${id}`,
      fragment: GET_SUBSCRIPTION_DETAIL_FRAGMENT,
      fragmentName: 'GetSubscriptionDetail',
      data: { ...obj },
    });
  }
  return;
};

export const getOptimisticSubscriptionResponse = ({
  subscriptionId,
  productId,
  newValue,
}: any) => {
  return {
    __typename: 'SubscriptionResponse',
    products: [
      {
        id: productId,
        rating: newValue,
        __typename: 'Product',
      },
    ],
    subscriptionId,
  };
};
