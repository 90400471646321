import { SCHEDULE_DETAILS_FRAGMENT } from '@graphql/fragments/order';

export const updateScheduleDetailsCache = ({
  cache,
  data,
  id,
  isMealProduct,
  isPantryProduct,
}: any) => {
  // read old schedule fragment
  const oldScheduleDetail = cache.readFragment({
    id: `Schedule:${id}`,
    fragment: SCHEDULE_DETAILS_FRAGMENT,
    fragmentName: 'ScheduleDetailsItem',
  }) as any;

  const { products } = data?.addProductFeedback || {};
  const feedbackProduct = products.length ? products[0] : {};

  let obj = { ...oldScheduleDetail };

  if (feedbackProduct?.rating || feedbackProduct?.rating === 0) {
    if (isMealProduct) {
      const order_products = oldScheduleDetail?.order_products?.map(
        ({ product, quantity }: any) => {
          if (product?.id === feedbackProduct?.id) {
            return {
              product: { ...product, rating: feedbackProduct?.rating },
              quantity,
              __typename: 'OrderProduct',
            };
          } else {
            return {
              product,
              quantity,
              __typename: 'OrderProduct',
            };
          }
        }
      );

      obj = {
        ...obj,
        order_products: order_products,
      };
    }

    if (isPantryProduct) {
      const pantryProducts = oldScheduleDetail?.pantryProducts?.map(
        ({ product, quantity }: any) => {
          if (product?.id === feedbackProduct?.id) {
            return {
              product: { ...product, rating: feedbackProduct?.rating },
              quantity,
              __typename: 'OrderProduct',
            };
          } else {
            return { product, quantity, __typename: 'OrderProduct' };
          }
        }
      );

      obj = {
        ...obj,
        pantryProducts: pantryProducts,
      };
    }

    //write schedule fragment
    cache.writeFragment({
      id: `Schedule:${id}`,
      fragment: SCHEDULE_DETAILS_FRAGMENT,
      fragmentName: 'ScheduleDetailsItem',
      data: { ...obj },
    });
  }
  return;
};
