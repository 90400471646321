import { gql } from '@apollo/client';

export const PROMO_CODE_DETAILS_MUTATION = gql`
  mutation GetPromoCodeDetails($input: GetPromoCodeDetailsInput!) {
    getPromoCodeDetails(input: $input) {
      referrerEmail
      name
      firstname
      lastname
      isPromoValid
      amount
      amountType
      promoText
      products {
        id
        image_url
        quantity
        title
      }
    }
  }
`;

export const GET_PROMO_CODE_FROM_REFERRAL_MUTATION = gql`
  mutation GetPromoCodeFromReferralCode(
    $input: GetPromoCodeFromReferralCodeInput!
  ) {
    getPromoCodeFromReferralCode(input: $input)
  }
`;

export const REFERRAL_CODE_DETAILS_MUTATION = gql`
  mutation Mutation($input: GetReferralCodeDetailsInput!) {
    getReferralCodeDetails(input: $input) {
      referrerEmail
      name
    }
  }
`;
