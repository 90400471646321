import { gql } from '@apollo/client';
import { PAYMENT_METHOD_FIELDS, SHIPPING_ADDRESS_FIELDS } from './order';

export const SUBSCRIPTION_PRODUCTS_FIELDS = gql`
  fragment SubscriptionProductFields on OrderProduct {
    quantity
    product {
      id
      title
      taste
      product_badge
      image_url
      rating
      isActive
    }
  }
`;

export const SUBSCRIPTION_PANTRY_PRODUCTS_FIELDS = gql`
  fragment SubscriptionPantryProductFields on OrderProduct {
    product {
      id
      title
      taste
      product_badge
      image_url
      secondaryImages
      isActive
      rating
    }
    quantity
  }
`;

export const SIDE_PRODUCTS_FIELDS = gql`
  fragment SideProductFields on OrderProduct {
    product {
      id
      title
      taste
      product_badge
      image_url
      secondaryImages
      isActive
      rating
    }
    quantity
  }
`;

export const SUBSCRIPTION_DEATILS_ITEMS = gql`
  fragment SubscriptionDetailsItems on SubscriptionResponse {
    id
    status
    frequency
    plan_id
    plan {
      id
      count_of_dishes
    }
    total_items
    mealPrice
    pantryPrice
    sidePrice
    total_price
    currencySymbol
    dayCount
    nextOrder {
      id
      schedule_date
    }
    planGroup
    isShopify
    sourceId
  }
`;

export const GET_SUBSCRIPTION_DETAIL_FRAGMENT = gql`
  fragment GetSubscriptionDetail on SubscriptionResponse {
    ...SubscriptionDetailsItems
    shipping_address {
      ...ShippingAddressFields
    }
    subscription_products {
      ...SubscriptionProductFields
    }
    pantryProducts {
      ...SubscriptionPantryProductFields
    }
    sideProducts {
      ...SideProductFields
    }
    payment_method {
      ...PaymentMethodFields
    }
  }
  ${SUBSCRIPTION_DEATILS_ITEMS}
  ${SHIPPING_ADDRESS_FIELDS}
  ${SUBSCRIPTION_PRODUCTS_FIELDS}
  ${SUBSCRIPTION_PANTRY_PRODUCTS_FIELDS}
  ${SIDE_PRODUCTS_FIELDS}
  ${PAYMENT_METHOD_FIELDS}
`;
