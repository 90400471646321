import { ORDER_DETAILS_FRAGMENT } from '@graphql/fragments/order';

export const updateOrderDetailsCache = ({
  cache,
  data,
  id,
  isMealProduct,
  isPantryProduct,
}: any) => {
  // read old subscription fragment
  const oldOrderDetail = cache.readFragment({
    id: `Order:${id}`,
    fragment: ORDER_DETAILS_FRAGMENT,
    fragmentName: 'OrderDetailsItem',
  }) as any;

  const { products } = data?.addProductFeedback || {};
  const feedbackProduct = products.length ? products[0] : {};

  let obj = { ...oldOrderDetail };

  if (feedbackProduct?.rating || feedbackProduct?.rating === 0) {
    if (isMealProduct) {
      const orderProducts = oldOrderDetail?.order_products?.map(
        ({ product, quantity }: any) => {
          if (product?.id === feedbackProduct?.id) {
            return {
              product: { ...product, rating: feedbackProduct?.rating },
              quantity,
              __typename: 'OrderProduct',
            };
          } else {
            return { product, quantity, __typename: 'OrderProduct' };
          }
        }
      );

      obj = {
        ...obj,
        order_products: orderProducts,
      };
    }

    if (isPantryProduct) {
      const pantryProducts = oldOrderDetail?.pantryProducts?.map(
        ({ product, quantity }: any) => {
          if (product?.id === feedbackProduct?.id) {
            return {
              product: { ...product, rating: feedbackProduct?.rating },
              quantity,
              __typename: 'OrderProduct',
            };
          } else {
            return { product, quantity, __typename: 'OrderProduct' };
          }
        }
      );

      obj = {
        ...obj,
        pantryProducts: pantryProducts,
      };
    }

    // write subscription fragment
    cache.writeFragment({
      id: `Order:${id}`,
      fragment: ORDER_DETAILS_FRAGMENT,
      fragmentName: 'OrderDetailsItem',
      data: { ...obj },
    });
  }
  return;
};

export const getOptimisticOrderResponse = ({
  subscriptionId,
  productId,
  newValue,
}: any) => {
  return {
    __typename: 'Order',
    id: 'orderId',
    subscriptionId,
    products: [
      {
        id: productId,
        rating: newValue,
        __typename: 'Product',
      },
    ],
  };
};
