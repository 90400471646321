import { GET_SUBSCRIPTION_DETAIL_FRAGMENT } from '@graphql/fragments/subscription';
import { gql } from 'graphql-tag';

export const GET_ALL_SUBSCRIPTIONS_QUERY = gql`
  query GetAllSubscriptions($first: Int, $after: String) {
    getAllSubscriptions(first: $first, after: $after) {
      total_count
      edges {
        node {
          id
          user_id
          status
          frequency
          plan_id
          total_items
          total_price
          shipping_address {
            id
            first_name
            last_name
            address_line_1
            address_line_2
            city
            state
            country_code
            zip_code
            phone_number
          }
        }
        cursor
      }
      page_info {
        end_cursor
      }
    }
  }
`;

export const GET_SUBSCRIPTION_BY_NODE_QUERY = gql`
  query getSubscriptionById($nodeId: ID!) {
    node(id: $nodeId, type: SUBSCRIPTION_RESPONSE) {
      ... on SubscriptionResponse {
        ...GetSubscriptionDetail
      }
    }
  }
  ${GET_SUBSCRIPTION_DETAIL_FRAGMENT}
`;

//TODO: later on fixed it
export const GET_SUBSCRIPTION_BY_ID_NODE_QUERY = gql`
  query Node($nodeId: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $nodeId, type: $type) {
      id
      ... on SubscriptionResponse {
        id
        user {
          id
          email
          name
          admin
          address_list {
            id
            first_name
            last_name
            address_line_1
            address_line_2
            city
            state
            country_code
            zip_code
            phone_number
          }
          referral_code
          isAccountActivated
        }
        user_id
        status
        frequency
        plan_id
      }
    }
  }
`;

export const ALL_PRODUCTS_QUERY = gql`
  query allProducts($subscriptionId: Int!) {
    getSubscription(subscription_id: $subscriptionId) {
      allProducts {
        id
        title
        taste
        image_url
        isActive
      }
    }
  }
`;
