import { gql } from '@apollo/client';

export const UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      id
      frequency
      product_ids_quantity {
        quantity
        product_id
      }
      productIdsQuantityPantry {
        product_id
        quantity
      }
      plan_id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_STATUS_MUTATION = gql`
  mutation ToggleSubscriptionStatus($input: ToggleSubscriptionStatusInput!) {
    toggleSubscriptionStatus(input: $input) {
      id
    }
  }
`;

export const ADD_PRODUCT_FEEDBACK_MUTATION = gql`
  mutation addProductFeedback($input: AddProductFeedbackInput!) {
    addProductFeedback(input: $input) {
      subscriptionId
      products {
        id
        rating
      }
    }
  }
`;

export const UPDATE_SCHEDULE_DATE_OF_SUBSCRIPTION = gql`
  mutation updateScheduleDateOfSubscription(
    $input: UpdateScheduleDateOfSubscriptionInput!
  ) {
    updateScheduleDateOfSubscription(input: $input) {
      nextOrder {
        schedule_date
        id
      }
      id
    }
  }
`;

export const CONVERT_ONE_TIME_ORDER_TO_SUBSCRIPTION = gql`
  mutation ConvertOneTimeOrderToSubscription(
    $input: ConvertOneTimeOrderToSubscriptionInput!
  ) {
    convertOneTimeOrderToSubscription(input: $input)
  }
`;

export const DIRECT_ACTIVATE_SUBSCRIPTION = gql`
  mutation DirectActivateSubscription($input: DirectActivateSubscriptionInput) {
    directActivateSubscription(input: $input)
  }
`;

export const UPDATE_SHOPIFY_PAYMENT_METHOD = gql`
  mutation updateShopifyPaymentMethod(
    $input: UpdateShopifyPaymentMethodInput!
  ) {
    updateShopifyPaymentMethod(input: $input) {
      id
      clientSecret
    }
  }
`;

export const SEND_SHOPIFY_PAYMNET_METHOD_UPDATE_EMAIL = gql`
  mutation SendShopifyPaymentMethodUpdateEmail(
    $input: SendShopifyPaymentMethodUpdateEmailInput!
  ) {
    sendShopifyPaymentMethodUpdateEmail(input: $input)
  }
`;
